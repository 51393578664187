import React from "react";
import "./WhoWeAre.css";
import Line from "../../assets/Line 1.svg";
import { useTranslation } from 'react-i18next';

export default function WhoWeAre() {
  const { t } = useTranslation()

  return (
    <div className="whoWeareMain">
      <div className="whoWeAre">
        <div className="aboutUs">
          <div className="aboutTitle">
            <div className="aboutUsMain">
              <img src={Line} alt="" />
              <p>{t('about.title1')}</p>
            </div>
            <h2>{t('about.title2')}</h2>
          </div>
          <div className="aboutUsPara">
            <p>
              {t('about.desc')}
            </p>
          </div>
        </div>
      </div>
      <div className="secondNumbers">
        <div className="numbers">
          <div className="crafts">
            <h1>12</h1>
            <h4>{t('about.number1')}</h4>
          </div>

          <div className="crafts">
            <h1>12</h1>
            <h4>{t('about.number2')}</h4>
          </div>

          <div className="crafts">
            <h1>7.2k</h1>
            <h4>{t('about.number3')}</h4>
          </div>

          <div className="crafts" style={{ borderRight: "none" }}>
            <h1>3%</h1>
            <h4>{t('about.number4')}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
