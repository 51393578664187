import { createContext, useState } from "react";

export const WalletContext = createContext()

export const WalletProvider = ({children}) => {
    const [method, setMethod] = useState()
    const [publicKey, setPublicKey] = useState()

    return (
        <WalletContext.Provider value={({method, setMethod, publicKey, setPublicKey})}>
            {children}
        </WalletContext.Provider>
    )
}