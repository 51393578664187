import React from "react";
import "./Roadmap.css";
import Line from "../../assets/Line 1.svg";
import Roadmaps from "../../assets/Roadmap.svg";
import Line2 from "../../assets/Line 2.png";
import { useTranslation } from 'react-i18next';

export default function Roadmap() {
  const { t } = useTranslation()

  return (
    <div className="roadmap">
      <div className="sliderTitle">
        <img src={Line} alt="" />
        <h4>{t('roadmap.title1')}</h4>
      </div>
      <h2>{t('roadmap.title2')}</h2>
      <img id="roadMaps" src={Roadmaps} alt="" />
      <div className="roadMapOptions">
        <div className="leftSide">
          <div className="odd">
            <p><strike>{t('roadmap.item1')}</strike></p>
          </div>
          <div className="inn">
            <p>{t('roadmap.item3')}</p>
          </div>
          <div className="odd">
            <p>{t('roadmap.item5')}</p>
          </div>
          <div className="inn">
            <p>{t('roadmap.item7')}</p>
          </div>
          <div className="odd">
            <p>{t('roadmap.item9')}</p>
          </div>
          <div className="inn">
            <p>{t('roadmap.item11')}</p>
          </div>
        </div>
        <img id="line2" src={Line2} alt="" />
        <div className="rightOption">
          <div className="inn">
            <p>{t('roadmap.item2')}</p>
          </div>
          <div className="odd">
            <p>{t('roadmap.item4')}</p>
          </div>
          <div className="inn">
            <p>{t('roadmap.item6')}</p>
          </div>
          <div className="odd">
            <p>{t('roadmap.item8')}</p>
          </div>
          <div className="inn">
            <p>{t('roadmap.item10')}</p>
          </div>
          <div className="odd">
            <p>{t('roadmap.item12')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
