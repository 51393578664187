import React from "react";
import "./Slider.css";
import { Carousel } from "react-bootstrap";
import Line from "../../assets/Line 1.svg";
import Chain from "../../assets/Group (1).svg";
import Customize from "../../assets/Group 36616.svg";
import Cake from "../../assets/Cake.svg";
import { useTranslation } from 'react-i18next';

export default function Slider() {
  const { t } = useTranslation()

  return (
    <div className="slider" id="slider">
      <div className="sliderTitle">
        <img src={Line} alt="" />
        <h4>{t('slider.title1')}</h4>
      </div>
      <h2 id="mainAdvantage">{t('slider.title2')}</h2>
      <div className="sliderItems">
        <Carousel>
          <Carousel.Item>
            <div className="sliderItemsMain">
              <div className="sliderItem">
                <div className="sliderImage">
                  <img src={Chain} alt="" />
                </div>
                <p>
                  {t('slider.item1_1')}<br/>
                  {t('slider.item1_2')}
                </p>
              </div>

              <div className="sliderItem">
                <div className="sliderImage">
                  <img src={Customize} alt="" />
                </div>
                <p>
                  {t('slider.item2_1')}<br/>
                  {t('slider.item2_2')}
                </p>
              </div>

              <div className="sliderItem">
                <div className="sliderImage">
                  <img src={Cake} alt="" />
                </div>
                <p>
                  {t('slider.item3_1')}<br/>
                  {t('slider.item3_2')}
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="sliderItemsMain">
              <div className="sliderItem">
                <div className="sliderImage">
                  <img src={Chain} alt="" />
                </div>
                <p>
                  {t('slider.item4_1')}<br/>
                  {t('slider.item4_2')}
                </p>
              </div>

              <div className="sliderItem">
                <div className="sliderImage">
                  <img src={Customize} alt="" />
                </div>
                <p>
                  {t('slider.item5_1')}<br/>
                  {t('slider.item5_2')}
                </p>
              </div>

              <div className="sliderItem">
                <div className="sliderImage">
                  <img src={Cake} alt="" />
                </div>
                <p>
                  {t('slider.item6_1')}<br/>
                  {t('slider.item6_2')}
                </p>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}
