
//import './App.css';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Keypair, PublicKey, clusterApiUrl, Transaction, SystemProgram, Connection } from '@solana/web3.js';
import axios from 'axios';
import { Button, Snackbar } from '@mui/material';
import { WalletContext } from '../providers/WalletProvider';
import { useTranslation, Trans } from 'react-i18next';
import Selector from './Selector';


function SelectAttributes() {
    const [flavor, setFlavor] = useState()
    const [topping, setTopping] = useState()
    const [eyes, setEyes] = useState()
    const [mouth, setMouth] = useState()
    const [hands, setHands] = useState()
    const [legs, setLegs] = useState()
    const [error, setError] = useState()
    const history = useHistory();
    const { method, publicKey } = useContext(WalletContext)
    const { t } = useTranslation()

    useEffect(() => {
        getProvider();
    })

    const getProvider = () => {
        if (method === 'SOL') {
            if ("solana" in window) {
                const provider = window.solana;
                if (provider.isPhantom) {
                    return provider;
                }
            }
            setError('Phantom Wallet not found');
        }
    };
    const data = {
        flavor: flavor,
        topping: topping,
        eyes: eyes,
        mouth: mouth,
        hands: hands,
        legs: legs,
        publicKey: publicKey
    }

    const attributes = {
        flavor: ['classic', 'dark_chocolate', 'white_chocolate', 'pistachio', 'gingerbread', 'lemon', 'peanut_butter', 'coconut', 'red_velvet', 'banana', 'chestnut', 'rice'],
        topping: ['milk_chocolate', 'white_chocolate', 'dark_chocolate', 'mms', 'lime', 'coconut', 'orange', 'hazelnut', 'sesame_seeds', 'caramel', 'raspberry', 'raisin'],
        eyes: ['happy', 'angry', 'sad', 'closed', 'burning', 'closedF', 'angryF', 'happyF', 'jovialF'],
        mouth: ['grin', 'happy', 'jovial', 'confused', 'bored', 'angry', 'drooling', 'angryF'],
        hands: ['finger', 'angry', 'furious', 'confused', 'cool', 'zen', 'happy', 'hello', 'victory', 'afraid'],
        legs: ['standing', 'standing2', 'standing3', 'standing4', 'floating', 'jumping', 'running'],
    }

    const validForm = async () => {
        if (method == 'SOL') {
            await sendSol();
        } else if (method == 'CB') {
            await sendCb();
        }
        axios.post("https://us-central1-binart.cloudfunctions.net/order", data).then(res => {

            history.push("/b-end")
        }).catch(e => {
            console.log(e)
        })

    }

    async function sendCb(e) {
        console.log('CB Payment')
        await axios.post('https://us-central1-binart.cloudfunctions.net/cb')

    }

    async function sendSol(e) {
        let connection = new Connection(clusterApiUrl('devnet'), 'confirmed');

        let toKeypair = Keypair.generate();
        let recentBlockhash = await connection.getRecentBlockhash();
        let transaction = new Transaction({
            recentBlockhash: recentBlockhash.blockhash,
            feePayer: new PublicKey(publicKey)
        });

        transaction.add(
            SystemProgram.transfer({
                fromPubkey: new PublicKey(publicKey),
                toPubkey: toKeypair.publicKey,
                lamports: 1000,
            })
        );


        const { signature } = await window.solana.signAndSendTransaction(transaction);
        await connection.confirmTransaction(signature);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(null);
    };

    const cancel = (event) => {
        history.push("/")
    }


    return (
        <>
            <div className="App selectAt">
                <header className="item-center">
                    <>
                        <h2>Select properties</h2>
                        <Selector attribute='flavor' value={flavor} onChange={(e) => { setFlavor(e.target.value) }} items={attributes.flavor} />
                        <Selector attribute='topping' value={topping} onChange={(e) => { setTopping(e.target.value) }} items={attributes.topping} />
                        <Selector attribute='eyes' value={topping} onChange={(e) => { setEyes(e.target.value) }} items={attributes.eyes} />
                        <Selector attribute='mouth' value={topping} onChange={(e) => { setMouth(e.target.value) }} items={attributes.mouth} />
                        <Selector attribute='hands' value={topping} onChange={(e) => { setHands(e.target.value) }} items={attributes.hands} />
                        <Selector attribute='legs' value={topping} onChange={(e) => { setLegs(e.target.value) }} items={attributes.legs} />
                        <div>
                            {method === 'SOL' && publicKey ?
                                <input type="button" onClick={validForm} value="Send nft"></input>
                                : method === 'CB' ?
                                    <form action="https://us-central1-binart.cloudfunctions.net/cb" method="POST">
                                        <Button variant="contained" type="submit">
                                            Payer
                                        </Button>
                                        <Button variant="contained" onClick={cancel}>
                                            Annuler
                                        </Button>
                                    </form> : null}
                        </div>
                    </>
                </header>
                <Snackbar
                    open={error}
                    autoHideDuration={1000}
                    onClose={handleClose}
                    message={error}
                />
            </div>
        </>
    );
}

export default SelectAttributes;