import React, { useState } from "react";
import "./Faq.css";
import Faqa from "../../assets/FAQ.svg";
import Line from "../../assets/Line 1.svg";
import DownArray from "../../assets/Group 36621.png";
import Uparray from "../../assets/Group 36624.png";
import { useTranslation } from 'react-i18next';

export default function Faq() {
  const [first, setFirst] = useState(false);
  const [first2, setFirst2] = useState(false);
  const [first3, setFirst3] = useState(false);
  const [first4, setFirst4] = useState(false);
  const [first5, setFirst5] = useState(false);
  const [first6, setFirst6] = useState(false);
  const { t } = useTranslation()

  return (
    <div className="faq" id="faq">
      <div className="sliderTitle" style={{ justifyContent: "left" }}>
        <img src={Line} alt="" />
        <h4>{t('faq.title1')}</h4>
      </div>
      <h2>{t('faq.title2')}</h2>
      <img id="holders" src={Faqa} alt="" class="faqh2" />

      <div className="accordion">
        <div className="firsLineAcc">
          <div className="firstAccordion">
            <div className="question">
              <p>
                {t('faq.question1')}
              </p>
              <img
                src={first ? Uparray : DownArray}
                alt=""
                onClick={() => setFirst(!first)}
              />
            </div>
            {first ? (
              <p id="answer">
                {t('faq.answer1')}
              </p>
            ) : null}
          </div>
          <div className="firstAccordion">
            <div className="question">
              <p>
                {t('faq.question2')}
              </p>
              <img
                src={first2 ? Uparray : DownArray}
                alt=""
                onClick={() => setFirst2(!first2)}
              />
            </div>
            {first2 ? (
              <p id="answer">
                {t('faq.answer2')}
              </p>
            ) : null}
          </div>
        </div>
        <div className="firsLineAcc">
          <div className="firstAccordion">
            <div className="question">
              <p>
                {t('faq.question3')}
              </p>
              <img
                src={first3 ? Uparray : DownArray}
                alt=""
                onClick={() => setFirst3(!first3)}
              />
            </div>
            {first3 ? (
              <p id="answer">
                {t('faq.answer3')}
              </p>
            ) : null}
          </div>
          <div className="firstAccordion">
            <div className="question">
              <p>
                {t('faq.question4')}
              </p>
              <img
                src={first4 ? Uparray : DownArray}
                alt=""
                onClick={() => setFirst4(!first4)}
              />
            </div>
            {first4 ? (
              <p id="answer">
                {t('faq.answer4')}
              </p>
            ) : null}
          </div>
          <div className="firstAccordion">
            <div className="question">
              <p>
                {t('faq.question5')}
              </p>
              <img
                src={first5 ? Uparray : DownArray}
                alt=""
                onClick={() => setFirst5(!first5)}
              />
            </div>
            {first5 ? (
              <p id="answer">
                {t('faq.answer5')}
              </p>
            ) : null}
          </div>
          <div className="firstAccordion">
            <div className="question">
              <p>
                {t('faq.question6')}
              </p>
              <img
                src={first6 ? Uparray : DownArray}
                alt=""
                onClick={() => setFirst6(!first6)}
              />
            </div>
            {first6 ? (
              <p id="answer">
                {t('faq.answer6')}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
