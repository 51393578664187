import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Navbar from './Navbar/Navbar';

class EndOfBying extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Navbar />
                <div className='d-flex flex-column align-items-center selectAt'>
                    <h3> Merci pour votre achat</h3>
                    <Link to="/mint"> Nouvelle achat</Link>
                </div>
            </>
        );
    }
}


export default EndOfBying;
