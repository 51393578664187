import React from "react";
import "./Main.css";
import Models from "../../assets/Models.svg";
import Fully from "../../assets/Fully.svg";
import MainLogo from "../../assets/Group 36609.svg";
import { useTranslation } from 'react-i18next';

export default function Main() {
  const { t } = useTranslation()

  return (
    <div className="main">
      <div className="firstLine">
        <h1 className="mainTitle">{t('main.title1')}</h1>
      </div>
      <div className="secondLine">
        <h1 className="mainTitle">{t('main.title2')}</h1>
      </div>
      <p className="mainPara">
        {t('main.desc1')}<br />
        {t('main.desc2')}
      </p>
      <img
        style={{ marginTop: "70px" }}
        src={MainLogo}
        alt=""
        id="headerBigLogo"
      />
    </div>
  );
}
