import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Navbar.css";
import { useTranslation, Trans } from 'react-i18next';
import { Button, ButtonGroup } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { MintNavbarContext } from "../../providers/MintNavbarProvider";

const lngs = {
  en: { nativeName: 'EN' },
  fr: { nativeName: 'FR' }
};



export default function Navbar() {
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const redirectToMint = () => {
    history.push("/mint")

    setIsMint(false)
  }

  const [isMint, setIsMint] = useContext(MintNavbarContext)

  const [hover, setHover] = useState();

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  return (
    // <<<<<<< HEAD
    //     <div className="container navbar">
    //       <div className="logobinart">
    //         <Link to='/'><h1>BINART</h1></Link>
    // =======
    <div className="container navbar">
      <Link to='/'><h1>BINART</h1></Link>
      <div className="menu">
        <a href="">{t('navbar.history')}</a>
        <a href="#slider">{t('navbar.advantages')}</a>
        <a href="#roadMaps">{t('navbar.roadmap')}</a>
        <a href="#faq">{t('navbar.faq')}</a>
        <Link to='/gallery'>{t('navbar.gallery')}</Link>
      </div>
      <div className="d-flex flex-row align-items-center mr2">
        <div className="navButons">
          <button id="mintButton" onClick={redirectToMint}>
            {t('navbar.mint')}
          </button>
        </div>
        <div>
          <ButtonGroup aria-label="Basic example">
            {Object.keys(lngs).map((lng) => (
              <>
                <Button variant="dark" onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>
                  {lngs[lng].nativeName}
                </Button>
              </>
            ))}
          </ButtonGroup>
        </div>
      </div>
      {
        history.location.pathname === '/' ?
          <>
            <div className="menu">
              <a href="">{t('navbar.history')}</a>
              <a href="#slider">{t('navbar.advantages')}</a>
              <a href="#roadMaps">{t('navbar.roadmap')}</a>
              <a href="/faq">{t('navbar.faq')}</a>
              <Link to='/gallery'>{t('navbar.gallery')}</Link>
            </div>
            <div className="d-flex flex-row align-items-center mr2">
              <div className="navButons">

                <button onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} id={hover ? "hoverend" : "mintButton"} onClick={redirectToMint}>
                  Mint
                </button>

              </div>
              <div>
                <ButtonGroup aria-label="Basic example">
                  {Object.keys(lngs).map((lng) => (
                    <>
                      <Button variant="dark" onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>
                        {lngs[lng].nativeName}
                      </Button>
                    </>
                  ))}
                </ButtonGroup>
              </div>
            </div>
          </>
          :
          <div className="d-flex flex-row align-items-center mr2">
            <div className="menu">
              <Link to='/gallery'>{t('navbar.gallery')}</Link>
            </div>
            <div>
              <ButtonGroup aria-label="Basic example">
                {Object.keys(lngs).map((lng) => (
                  <>
                    <Button variant="dark" onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>
                      {lngs[lng].nativeName}
                    </Button>
                  </>
                ))}
              </ButtonGroup>
            </div>
          </div>
      }

    </div>
  );
}


