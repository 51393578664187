import { Button } from 'react-bootstrap'
import { useContext } from 'react';
import { WalletContext } from '../providers/WalletProvider'
import { useTranslation, Trans } from 'react-i18next';
import Navbar from './Navbar/Navbar';

const lngs = {
    en: { nativeName: 'EN' },
    fr: { nativeName: 'FR' }
};

function Header() {
    const { method, publicKey, setPublicKey } = useContext(WalletContext)
    const { t, i18n } = useTranslation()

    async function connectSol(e) {
        try {
            const resp = await window.solana.connect();
            setPublicKey(resp.publicKey.toString())
        } catch (err) {
            console.log(e)
        }
    }

    return (
        <>
            <Navbar></Navbar>
            <div className='d-flex flex-column align-items-center selectAt'>
                <p>{publicKey}</p>
                <div className='item-center '>
                    {method === 'SOL' && !publicKey ?
                        <Button variant="primary" onClick={connectSol}>Connect</Button> :
                        null}
                </div>
            </div>

        </>
    )
}

export default Header;
