import React from "react";
import "./AdvantageMinter.css";
import Line from "../../assets/Line 1.svg";
import Mintrs from "../../assets/Minters.svg";
import { useTranslation } from 'react-i18next';

export default function AdvanceMinter() {
  const { t } = useTranslation()

  return (
    <div className="advanceMinterMAin">
      <div className="advantageMinter">
        <div className="advantageMinterTitle">
          <div className="sliderTitle" style={{ justifyContent: "left" }}>
            <img src={Line} alt="" />
            <h4>{t('minter.title1')}</h4>
          </div>
          <h2>{t('minter.title2')}</h2>
        </div>
        <img src={Mintrs} alt="" id="advantageMinter" />
      </div>
      <div className="minterAdvantage">
        <div className="advantageFirstline">
          <div className="advantage">
            <h4>01</h4>
            <p>
              {t('minter.adv1')}
            </p>
          </div>
          <div className="advantage">
            <h4>02</h4>
            <p>
              {t('minter.adv2')}
            </p>
          </div>
          <div className="advantage">
            <h4>03</h4>
            <p>
              {t('minter.adv3')}
            </p>
          </div>
        </div>
        <div className="advantageFirstline">
          <div className="advantage">
            <h4>04</h4>
            <p>
              {t('minter.adv4')}
            </p>
          </div>
          <div className="advantage">
            <h4>05</h4>
            <p>
              {t('minter.adv5')}
            </p>
          </div>
          <div className="advantage">
            <h4>06</h4>
            <p>
              {t('minter.adv6')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
