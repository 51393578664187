import React from "react";
import "./AdvantageHolder.css";
import Line from "../../assets/Line 1.svg";
import Holders from "../../assets/Holders.svg";
import { useTranslation } from 'react-i18next';

export default function AdvantageHolder() {
  const { t } = useTranslation()

  return (
    <div className="advantageHolder">
      <div className="sliderTitle" style={{ justifyContent: "left" }}>
        <img src={Line} alt="" />
        <h4>{t('holder.title1')}</h4>
      </div>
      <h2>{t('holder.title2')}</h2>
      <img id="holders" src={Holders} alt="" />
      <div className="holderAdvantages">
        <div className="holderAdvantage">
          <h4>01</h4>
          <p>
            {t('holder.adv1')}
          </p>
        </div>
        <div className="holderAdvantage">
          <h4>02</h4>
          <p>
          {t('holder.adv2')}
          </p>
        </div>
        <div className="holderAdvantage">
          <h4>03</h4>
          <p>
          {t('holder.adv3')}
          </p>
        </div>
        <div className="holderAdvantage">
          <h4>04</h4>
          <p>
          {t('holder.adv4')}
          </p>
        </div>
      </div>
    </div>
  );
}
