import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import SelectWallet from './components/SelectWallet';
import EndOfBying from './components/EndOfBying';
import { WalletProvider } from './providers/WalletProvider';
import Home from './Home';
import { Nav } from 'react-bootstrap';
import Navbar from './components/Navbar/Navbar';
import { MintNavbarProvider } from './providers/MintNavbarProvider';

function App() {
  return (

    <MintNavbarProvider>
      <WalletProvider>
        <Router>
          <Switch>
            <Route path="/" exact component={Home}></Route>
            <div className='heromint'>
              <Route path="/mint" exact component={SelectWallet}></Route>
              <Route path="/b-end" component={EndOfBying}></Route>
            </div>
          </Switch>
        </Router>
      </WalletProvider>
    </MintNavbarProvider>

  )
}

export default App;
