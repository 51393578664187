import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          creditCard: 'Credit Card',
          navbar: {
            history: 'Our History',
            advantages: 'Advantages',
            roadmap: 'Roadmap',
            faq: 'FAQ',
            gallery: 'Gallery',
            mint: 'Mint'
          },
          main: {
            title1: 'Yummy Cookies',
            title2: 'Unique NFT Collection',
            desc1: '100% customizable and tasteable NFT Collection',
            desc2: 'Based on the first Proof of Hungry Blockchain'
          },
          about: {
            title1: 'History',
            title2: 'Who We Are',
            desc: 'A bit of history...TBD',
            number1: 'Flavours',
            number2: 'Toppings',
            number3: 'Max Items',
            number4: 'Lifetime Royalties'
          },
          slider: {
            title1: 'Advantages',
            title2: 'Main Advantages',
            item1_1: 'Multichain',
            item1_2: 'NFT Collection',
            item2_1: 'Customizable',
            item2_2: 'NFT Collection',
            item3_1: 'NFT Baking &',
            item3_2: 'Delivery in 24-48h',
            item4_1: 'Credit Card',
            item4_2: 'Payments Available',
            item5_1: 'Limited to 7.2k units',
            item5_2: 'accross all supported blockchains',
            item6_1: '1% of all payments will',
            item6_2: 'contribute to the Planet'
          },
          minter: {
            title1: 'Advantages',
            title2: 'For Minters',
            adv1: 'Select your own Flavour and Customization',
            adv2: 'Chose your favorite Blockchain for minting',
            adv3: 'Lifetime Royalties on secondary sales',
            adv4: 'NFTs will be sellable in the compatible Marketplaces',
            adv5: 'Pre-sale exclusive price starts at 59$',
            adv6: ''
          },
          holder: {
            title1: 'Advantages',
            title2: 'For Holders (Minters or Secondary sales)',
            adv1: 'Grants Access to the Yummy Cookies Shop (Q2 2022)',
            adv2: 'Future Access to the Binart Marketplace (Q3 2022)',
            adv3: 'NFTs will be sellable in the compatible Marketplaces',
            adv4: 'Unlock Future Roadmap features'
          },
          roadmap: {
            title1: 'Roadmap',
            title2: 'What is cooking for the future ?',
            item1: 'Yummy Cookies',
            item2: 'Pre-Sale',
            item3: 'Discord Server',
            item4: 'Crowdfunding Campaign',
            item5: 'Solana',
            item6: 'Cookie Shop Experience',
            item7: 'Ethereum',
            item8: 'Binance Smart Chain',
            item9: 'Other Blockchains on demand',
            item10: 'Child Collection',
            item11: 'Binart Marketpace',
            item12: 'Future Requests'
          },
          faq: {
            title1: 'FAQ',
            title2: 'Frequently Asked Questions',
            question1: 'Which Blockchains and Wallets are supported ?',
            answer1: 'You can buy off-chain without wallet via Credit Card. Or use Phantom for Solana (Q2 2022). And Metamask for Ethereum, BSC (Q3 2022)',
            question2: 'Which currencies are accepted ?',
            answer2: 'For Pre-Sale : USD & EUR. Then ETH for Ethereum, SOL for Solana, and BNB for BSC',
            question3: 'What happens when I buy my NFT with USD or EUR ?',
            answer3: "In this case, the NFT will be baked like all others and uploaded to a permanent File System. You will have 2 choices to redeem it : - Wait for the Binart marketplace to have access to your portfolio without any Blockchain complication - Install a portfolio of a supported Blockchain to redeem you NFT",
            question4: 'What happens when I buy my NFT with Crypto ? (Available Q2 2022)',
            answer4: 'The NFT will be baked (24 to 48 hours in the oven), minted and airdropped to your wallet.',
            question5: 'What exactly is the Cookie Store ?',
            answer5: "We cannot say much but if you like Yummy Cookies, you won't miss this one. Watch out for Q2 2022.",
            question6: 'Can I sell my Yummy Cookie on a marketplace ?',
            answer6: 'All NFTs will be sellable in the upcoming Binart Marketplace. And all will also be sellable in most known On-Chain Marketplaces',
          },
          footer: {
            title: 'Yummy Cookies'
          },
          flavor: {
            label: 'Flavor',
            classic: 'Classic'
          },
          topping: {
            label: 'Topping',
            milk: 'Milk Chocolate',
            white: 'White Chocolate',
            dark: 'Dark Chocolate',
            mms: 'M&Ms'
          },
          eyes: {
            label: 'Eyes',
            happy: 'Happy',
            angry: 'Angry',
            sad: 'Sad',
            closed: 'Closed',
            burning: 'Burning',
            closedF: 'Closed Feminine',
            angryF: 'Angry Feminine',
            happyF: 'Happy Feminine',
            jovialF: 'Jovial Feminine'
          },
          mouth: {
            label: 'Mouth',
            grin: 'Grin',
            happy: 'Happy',
            jovial: 'Jovial',
            confused: 'Confused',
            bored: 'Bored',
            angry: 'Angry',
            drooling: 'Drooling',
            angryF: 'Angry Feminine'
          },
          hands: {
            label: 'Hands',
            finger: 'Finger',
            angry: 'Angry',
            furious: 'Furious',
            confused: 'Confused',
            cool: 'Cool',
            zen: 'Zen',
            happy: 'Happy',
            hello: 'Hello',
            victory: 'Victory',
            afraid: 'Afraid'
          },
          legs: {
            label: 'Legs',
            standing: 'Standing',
            standing2: 'Standing 2',
            standing3: 'Standing 3',
            standing4: 'Standing 4',
            floating: 'Floating',
            jumping: 'Jumping',
            running: 'Running'
          }
        }
      },
      fr: {
        translation: {
          creditCard: 'Credit Card',
          navbar: {
            history: 'Notre Histoire',
            advantages: 'Avantages',
            roadmap: 'Roadmap',
            faq: 'FAQ',
            gallery: 'Galerie',
            mint: 'Créer'
          },
          main: {
            title1: 'Yummy Cookies',
            title2: 'Collection NFT Unique',
            desc1: 'Collection NFT 100% personnalisable et comestible',
            desc2: 'Un projet qui ne consomme que de la nourriture'
          },
          about: {
            title1: 'Histoire',
            title2: 'Qui sommes-nous ?',
            desc: 'A bit of history...TBD',
            number1: 'Bases',
            number2: 'Garnitures',
            number3: 'NFTs max',
            number4: 'Royalties'
          },
          slider: {
            title1: 'Avantages',
            title2: 'Principaux Avantages',
            item1_1: 'Collection NFT',
            item1_2: 'Multi-chaine',
            item2_1: 'Cookies',
            item2_2: 'Personnalisables',
            item3_1: 'Cookies créés et',
            item3_2: 'envoyés en 24-48h',
            item4_1: 'Paiement possible par',
            item4_2: 'Carte de Crédit',
            item5_1: 'Limité à 7200',
            item5_2: 'répartis sur les Blockchains',
            item6_1: '1% des paiments',
            item6_2: 'contribuent à la planète'
          },
          minter: {
            title1: 'Avantages',
            title2: 'Pour les créateurs',
            adv1: 'Chaoisir ses parfums et la personnalisation',
            adv2: 'Choisir sa Blockchain favorite',
            adv3: 'Royaties à vie sur les futures ventes',
            adv4: 'Les NFTs pourront être mis en vente sur les places de marché compatibles',
            adv5: 'Le tarif de pré-vente préférentiel commence à 59$',
            adv6: ''
          },
          holder: {
            title1: 'Avantages',
            title2: 'Pour les possesseurs de Yummy Cookies',
            adv1: 'Donne accès à la boutique Yummy Cookies (Q2 2022)',
            adv2: "Futur accès à la bourse d'échange Binart (Q3 2022)",
            adv3: 'Les NFT pourront être mis en vente sur les autres places de marché compatibles',
            adv4: 'Accéder aux autres éléments futurs de la Roadmap'
          },
          roadmap: {
            title1: 'Roadmap',
            title2: "Qu'est ce qu'on vous prépare pour bientôt ?",
            item1: 'Yummy Cookies',
            item2: 'Prévente',
            item3: 'Serveur Discord',
            item4: 'Campaign de crowdfunding',
            item5: 'Solana',
            item6: 'La boutique Yummy Cookies',
            item7: 'Ethereum',
            item8: 'Binance Smart Chain',
            item9: 'Autres Blockchains à la demande',
            item10: 'Collections liées',
            item11: 'Binart Marketpace',
            item12: 'Demandes futures'
          },
          faq: {
            title1: 'FAQ',
            title2: 'Foire Aux Questions',
            question1: 'Quelles Blockchain et Portefeuilles sont supportés ?',
            answer1: 'On peut commander via Carte de crédit, sans connaissance en Blockchain. Ou utiliser Phantom pour Solana (Q2 2022). Ainsi que Metamask pour Ethereum et BSC (Q3 2022)',
            question2: 'Quelles devises sont acceptées ?',
            answer2: 'Poour la prévente : USD & EUR. Puis ETH pour Ethereum, SOL pour Solana, et BNB pour BSC',
            question3: 'Que se passe-t-il après mon achat en EUR ou USD ?',
            answer3: "Dans ce cas, mon NFT sera créé comme les autres, et uploadé sur un stockage persistant décentralisé. Il y aura alors 2 choix pour le récupérer : - Attendre Binart marketplace pour accéder à mes NFT de façon transparente - Installer un portefeuille su une Blockchain supportée pour le récupérer",
            question4: 'Que se passe-t-il après mon achat en cryptomonnaie ? (Disponible Q2 2022)',
            answer4: 'Le NFT sera créé (24 à 48 heures au four), et envoyé directement sur votre portefeuille Crypto.',
            question5: "Qu'est-ce que la boutique de Cookies ?",
            answer5: "On ne peut encore trop en dire, mais si vous aimez les Yummy Cookies, vous ne manquerez pas cette news pour Q2 2022.",
            question6: 'Puis-je revendre mon Yummy Cookie sur une place de marché ?',
            answer6: 'Tous les NFT pourront être revendus sur la Binart Marketplace. Et tous pourront aussi etre vendus sur la plupart des places de marché.',
          },
          footer: {
            title: 'Yummy Cookies'
          },
          flavor: {
            label: 'Flavor',
            classic: 'Classic'
          },
          topping: {
            label: 'Topping',
            milk: 'Milk Chocolate',
            white: 'White Chocolate',
            dark: 'Dark Chocolate',
            mms: 'M&Ms'
          },
          eyes: {
            label: 'Eyes',
            happy: 'Happy',
            angry: 'Angry',
            sad: 'Sad',
            closed: 'Closed',
            burning: 'Burning',
            closedF: 'Closed Feminine',
            angryF: 'Angry Feminine',
            happyF: 'Happy Feminine',
            jovialF: 'Jovial Feminine'
          },
          mouth: {
            label: 'Mouth',
            grin: 'Grin',
            happy: 'Happy',
            jovial: 'Jovial',
            confused: 'Confused',
            bored: 'Bored',
            angry: 'Angry',
            drooling: 'Drooling',
            angryF: 'Angry Feminine'
          },
          hands: {
            label: 'Hands',
            finger: 'Finger',
            angry: 'Angry',
            furious: 'Furious',
            confused: 'Confused',
            cool: 'Cool',
            zen: 'Zen',
            happy: 'Happy',
            hello: 'Hello',
            victory: 'Victory',
            afraid: 'Afraid'
          },
          legs: {
            label: 'Legs',
            standing: 'Standing',
            standing2: 'Standing 2',
            standing3: 'Standing 3',
            standing4: 'Standing 4',
            floating: 'Floating',
            jumping: 'Jumping',
            running: 'Running'
          }
        }
      },
    }
  });

export default i18n;