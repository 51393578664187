import { createContext, useState } from "react";

export const MintNavbarContext = createContext()

export const MintNavbarProvider = ({ children }) => {
    const [isMint, setIseMint] = useState(true)


    return (
        <MintNavbarContext.Provider value={[isMint, setIseMint]}>
            {children}
        </MintNavbarContext.Provider>
    )
}