import React, { useContext } from 'react';
import SelectAttributes from './SelectAttributes';
import { Button } from 'react-bootstrap';
import { WalletContext } from '../providers/WalletProvider';
import { useTranslation, Trans } from 'react-i18next';
import Header from '../components/Header';
// import Footer from '../components/Footer';
import Footer from './Footer/Footer';


function SelectWallet() {
    const { method, setMethod } = useContext(WalletContext)
    const { t } = useTranslation();

    const selectMethod = (method) => {
        setMethod(method)
    }

    return (
        method == null ?
            <>
                <Header></Header>
                <div className="d-flex flex-row justify-content-center m-l-auto">
                    <Button disabled variant="warning" onClick={() => selectMethod('SOL')}>
                        SOLANA
                    </Button>
                    <Button variant="warning" onClick={() => selectMethod('CB')}>
                        {t('creditCard')}
                    </Button>
                </div>
                <div className='footerfix'>
                    <Footer></Footer>
                </div>
            </>
            :
            <>
                <Header></Header>
                <SelectAttributes></SelectAttributes>
                <Footer></Footer>
            </>
    );
}

export default SelectWallet;
