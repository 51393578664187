import {Select, InputLabel} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation, Trans } from 'react-i18next';

function Selector(props) {
    const attribute = props.attribute
    const { t } = useTranslation()

    return (
        <>
        <InputLabel id={attribute}>{t(attribute.concat('.label'))}</InputLabel>
        <Select
            labelId={attribute}
            id={attribute}
            value={props.value}
            onChange={props.onChange}>
                {props.items.map((item) => 
                    <MenuItem value={item}>{t(attribute.concat('.').concat(item))}</MenuItem>
                )}
        </Select>
        </>
    )
}

export default Selector